<template>
  <div class="study-box">
    <!-- <el-button type="primary" @click="close">主要按钮</el-button> -->
    <div class="dialogue-box">
      <div class="dialog-title">
        <data class="dialog-title2">
          <img class="mytrimg" :src="trimg" alt="" />
          <!-- @click="cutr()" -->
          <div class="switchover" >切换老师</div>
        </data>
      </div>
      <div class="dialog-body">
        <div class="dialog-body2">
          <div class="AI-dialogue">
            <div class="aierror" v-for="(item,index) in ywtList" :key="index"> 
              {{ item }}
            </div>
            
            
          </div>
          <div class="pressbtn" @touchstart="startSpeaking" @touchend="stopSpeaking">{{ doltxt }}</div>
          

        </div>
      </div>
    </div>
    <!-- <div class="textbook2">
      <div class="books-left">
        <div class="skahsfi"></div>
        <div class="saskan"></div>


      </div>
      <div class="cut-off"></div>
      <div class="books-rift"></div>

      
    </div> -->
    <div class="textbook">
      <div class="book-left">



        <!-- <div class="auto-recorder" style="">
          <canvas ref="canvas"></canvas>
          <button @click="toggleRecording" :disabled="isRecording">
            {{ isRecording ? "录音中" : "未录音" }}
          </button>
          <button class="" @click="ksly">录音</button>

          <audio v-if="audioUrl" controls :src="audioUrl"></audio>
        </div> -->
        
        <iframe
          
            ref="myIframe"
            :src="viewerUrl"
            frameborder="0"
            style="width:100%;height:100vh;display: none;"
            id="my-iframe"
        ></iframe>
    
        <div
          class="book-cont-title"
          v-for="(item, index) in content"
          :key="index"
        >
          <img
            class="bookimg"
            v-if="item.type == 'img'"
            :src="item.content"
            alt="Image"
          />
          <div class="bookTitle" v-else-if="item.type == 'title'">
            {{ item.content }}
          </div>
          <!-- @click="onstudy(item.content)" -->
          <div class="bookText"  v-else-if="item.type == 'content'">
            {{ item.content }}
          </div>
        </div>
      </div>
      <div class="book-right">
        <div class="book-zsd-box">
          <div class="tmzsj"></div>
          <div>Grammar Focus</div>

          <div class="tmzsj2"></div>
        </div>
        <div class="dfisiha">
          <div class="zsdtext" v-for="(item, index) in knowledge" :key="index">
            <div class="skanfj">
              <img
                class="zsdzd"
                src="../assets/xiaobangyy/czbtn/xx.png"
                alt=""
              />
              <!--  -->
              <div class="zsdtesxt" @click="onstudy(item)">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
        <div class="zsdjdbox">
      


          <div class="status-box" v-if="study_plan == 0">
              <div class="wcstatus-1"></div>
              <div class="wcwire1"></div>
              <div class="status-2"></div>
              <div class="wire"></div>
              <div class="status-3"></div>
            </div>
            <div class="status-box" v-else-if="study_plan == 1">
              <div class="wcstatus-1"></div>
              <div class="wcwire1"></div>
              <div class="wcstatus-2"></div>
              <div class="wire"></div>
              <div class="status-3"></div>
            </div>
            <div class="status-box" v-else-if="study_plan == 2">
              <div class="wcstatus-1"></div>
              <div class="wcwire1"></div>
              <div class="wcstatus-2"></div>
              <div class="wcwire1"></div>
              <div class="wcstatus-3"></div>
            </div>
            <div class="status-box" v-else>
              <div class="status-1"></div>
              <div class="wire"></div>
              <div class="status-2"></div>
              <div class="wire"></div>
              <div class="status-3"></div>
            </div>


        </div>
      </div>
      <div class="close-box" @click="close"></div>
      <div class="triangle-box">
        <div v-for="(item, index) in zsdbtn" :key="index">
          <div
            @click="zsdcut(item)"
            class="triangle"
            :style="{
              backgroundImage: 'url(' + item.url + ')',
              backgroundPosition: item.zb,
              marginTop: item.top,
              zIndex: item.zsdindex,
            }"
          >
            <span class="trtext">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="anew-box">

   
      </div>
    </div>
    <!-- <newsHint class="aksbfxx"></newsHint> -->





    <div class="xxbox" v-if="dljsas">
        <div class="asfjx">
            <img class="lajjs" src="../assets/xiaobangyy/pop/l-y.png" alt="">
            <img class="rajjs" src="../assets/xiaobangyy/pop/r-y.png" alt="">
            <div class="sdksnjg">
                <div class="jsxfd">
                    <div class="vkah">提示</div>
                </div>
                <div style="width: 100%;">
                    <div class="aksuzjxf">
                      这节课我们来学习：{{ poptitle }}
                     <br>
                    </div> 
                    <div class="ksasgd">
                        <img src="../assets/xiaobangyy/pop/yhzl.png" alt="" @click="guanbi">
                        <img src="../assets/xiaobangyy/pop/ljks.png" alt="" @click="ConfirmOk">
                    </div>
                </div>
               

            </div>

        </div>
    </div>

  </div>
</template>
<!-- <script src="../components/score/example/fast-xml-parser.min.js"  ></script>
<script src="../components/score/example/crypto-js.js"  ></script>
<script src="../components/score/dist/index.umd.js"  ></script>
<script src="../components/score/example/ise/index.js"  ></script> -->
<script>
import * as uploadFiles from "@/api/uploadFiles";

// import "../components/score/example/fast-xml-parser.min";
// import CryptoJS from "../components/score/example/crypto-js";
// import "../components/score/dist/index.umd";
// import "../components/score/example/ise/demo";

// const APPID = "1045772c";
//       const API_SECRET = "ZmY0ODNlMmEwNTY5OWQzNzRiYWNhOTBm";
//       const API_KEY = "80593b79ab38a6b54786f12a084835a5";
// import newsHint from "../components/popup.vue";
export default {
  components:{
    // newsHint
  },
  created(e) {
    this.course_id = this.$route.query.course_id;
    console.log(this.course_id);
    this.MyAiteacher();
    this.getstudycentre();
    console.log();

    // 提前获取第一次朗读
    this.onreadAloud()
  },

  data() {
    return {
      doltxt:'按住  说话',
      startSun:0,
      startTime:null,
      isLongPress: false,
        timer: null,
      dljsas:true,
      study_plan:0,
      textnr:[],
      ywtList:[
        '同学你好，欢迎来到小帮英语！'
       
      ],
      poptitle:'',
      iseWS: null,
      chunks: [],
      isRecording: false, //显示当前录音状态
      mediaRecorder: null,
      audioChunks: [], //存储录音数据的数组
      audioContext: null, //用于处理音频
      analyser: null, //用于分析音频频谱
      bufferSize: 2048, //用于设置分析音频的缓冲区大小。
      threshold: 0, //用于设置录音阈值的属性 db
      canvasContext: null, //用于存储 Canvas 上下文的属性。
      canvasWidth: 400,
      canvasHeight: 200,
      silentTime: 0, //记录静音时间
      audioUrl: "",
      silenceInterval: null, //计时器ID
      average: null,
      viewerUrl:'/score/example/ise/index.html',
      timer: null,
      trimg: "",
      course_id: "",
      content: [],
      knowledge: [],
      sdgaADh: {},
      audioData: "",
      asgufih:'',
      zsdbtn: [
        {
          url: require("../assets/xiaobangyy/czbtn/zsdxz.png"),
          name: "A",
          zb: "76px 155px",
          top: "",
        },
        {
          url: require("../assets/xiaobangyy/czbtn/zsdxz.png"),
          name: "B",
          zb: "-154px 114px",
          top: "",
        },
        {
          url: require("../assets/xiaobangyy/czbtn/zsdxz.png"),
          name: "C",
          zb: "76px 74px",
          top: "",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    if (localStorage.getItem("user") == null) {
      // 第一次进入学习
      this.firstLearning('第一次进入');
    }else {
      this.firstLearning('不是');

    }
     // 监听iframe发送的消息
    //  window.addEventListener('message', (event) => {
    //   console.log(event,'777');
      
    //   if (event.origin !== window.location.origin) return;
    //   console.log('接收到来自iframe的数据:', event.data);
    //   if(event.data == '正确') {
    //     // 朗读没有问题
    //     this.readContinue()
    //   }
    //   if(event.data.length) {
    //     // 朗读出错
    //     if(event.data == '正确')return
    //     console.log(typeof(event.data));
        
    //     this.ReadAgain(event.data)
    //   }
    //   if(event.data.data == undefined) {
    //     return
    //   }
      
       
    // });

    window.onmessage = (event) => {
      // 根据上面制定的结构来解析iframe内部发回来的数据				
      console.log(event,'777');
      
      if (event.origin !== window.location.origin) return;
      console.log('接收到来自iframe的数据:', event.data);
      if(event.data == '正确') {
        // 朗读没有问题
        this.readContinue()
      }
      if(event.data.length) {
        // 朗读出错
        if(event.data == '正确')return
        console.log(typeof(event.data));
        
        this.ReadAgain(event.data)
      }
      if(event.data.data == undefined) {
        return
      }
      
    };
  },
  methods: {
    // 第一次进入学习

    // convertBlobToArrayBuffer(blob) {
    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     const arrayBuffer = e.target.result;
    //     // 在这里处理你的ArrayBuffer
    //     console.log(arrayBuffer);

    //     this.iseWS.send(
    //       JSON.stringify({
    //         business: {
    //           aue: "raw",
    //           cmd: "auw",
    //           aus: true ? 4 : 2,
    //         },
    //         data: {
    //           status: true ? 2 : 1,
    //           data: this.toBase64(arrayBuffer),
    //           data_type: 1
    //         },
    //       })
    //     );

    //   };
    //   reader.readAsArrayBuffer(blob);
    // },

    // //
    // getWebSocketUrl() {
    //   console.log('00000');

    //   // 请求地址根据语种不同变化
    //   var url = "wss://ise-api.xfyun.cn/v2/open-ise";
    //   var host = "ise-api.xfyun.cn";
    //   var apiKey = API_KEY;
    //   var apiSecret = API_SECRET;
    //   var date = new Date().toGMTString();
    //   var algorithm = "hmac-sha256";
    //   var headers = "host date request-line";
    //   var signatureOrigin = `host: ${host}\ndate: ${date}\nGET /v2/open-ise HTTP/1.1`;
    //   var signatureSha = CryptoJS.HmacSHA256(signatureOrigin, apiSecret);
    //   var signature = CryptoJS.enc.Base64.stringify(signatureSha);
    //   var authorizationOrigin = `api_key="${apiKey}", algorithm="${algorithm}", headers="${headers}", signature="${signature}"`;
    //   var authorization = btoa(authorizationOrigin);
    //   url = `${url}?authorization=${authorization}&date=${date}&host=${host}`;
    //   return url;
    // },
    //  connectWebSocket() {
    //   const websocketUrl = this.getWebSocketUrl();

    //   if ("WebSocket" in window) {
    //     this.iseWS = new WebSocket(websocketUrl);
    //   } else if ("MozWebSocket" in window) {
    //     this.iseWS = new MozWebSocket(websocketUrl);
    //   } else {
    //     alert("浏览器不支持WebSocket");
    //     return;
    //   }
    //   // changeBtnStatus("CONNECTING");
    //    this.iseWS.onopen = (e) => {
    //     // 开始录音
    //    this.startRecording()
    //     var params = {
    //       common: {
    //         app_id: APPID,
    //       },
    //       business: {
    //         category: "read_sentence", // read_syllable/单字朗读，汉语专有 read_word/词语朗读  read_sentence/句子朗读 https://www.xfyun.cn/doc/Ise/IseAPI.html#%E6%8E%A5%E5%8F%A3%E8%B0%83%E7%94%A8%E6%B5%81%E7%A8%8B
    //         rstcd: "utf8",
    //         group: "pupil",
    //         sub: "ise",
    //         tte: "utf-8",
    //         cmd: "ssb",
    //         auf: "audio/L16;rate=16000",
    //         ent: "en_vip",
    //         aus: 1,
    //         aue: "raw",
    //         text:
    //           "\uFEFF" +
    //           (document.getElementById("text")?.value || "good morning"),
    //       },
    //       data: {
    //         status: 0,
    //         // data_type: 1,
    //         // encoding: "raw",
    //       },
    //     };
    //     this.iseWS.send(JSON.stringify(params));
    //   };
    //   this.iseWS.onmessage = (e) => {
    //     console.log(e.data);

    //     this.renderResult(e.data);
    //   };
    //   this.iseWS.onerror = (e) => {
    //     console.error(e);
    //     // recorder.stop();
    //     // changeBtnStatus("CLOSED");
    //   };
    //   this.iseWS.onclose = (e) => {
    //     // recorder.stop();
    //     // changeBtnStatus("CLOSED");
    //   };
    // },

    // renderResult(resultData) {
    //   // 识别结束
    //   let jsonData = JSON.parse(resultData);
    //   console.log(jsonData.data);

    //   if (jsonData.data && jsonData.data.data) {
    //     let data = window.atob(jsonData.data.data);
    //     let grade = parser.parse(data, {
    //       attributeNamePrefix: "",
    //       ignoreAttributes: false,
    //     });
    //     console.log(grade);
    //     const readSentence =
    //       grade?.xml_result?.read_sentence?.rec_paper?.read_chapter;
    //     document.getElementById("accuracy_score").innerText =
    //       readSentence?.accuracy_score;
    //     document.getElementById("fluency_score").innerText =
    //       readSentence?.fluency_score;
    //     document.getElementById("integrity_score").innerText =
    //       readSentence?.integrity_score;
    //     document.getElementById("phone_score").innerText =
    //       readSentence?.phone_score || 0;
    //     document.getElementById("tone_score").innerText =
    //       readSentence?.tone_score || 0;
    //     document.getElementById("emotion_score").innerText =
    //       readSentence?.emotion_score || 0;
    //     document.getElementById("total_score").innerText =
    //       readSentence?.total_score;
    //     let sentence = readSentence?.word || [];
    //     let as = readSentence?.sentence?.word
    //     as.map(item => {
    //       if(item.total_score<4) {
    //         document.getElementById("saWA").innerText =item.content;
    //       }
    //     })
    //     console.log(as);
    //     let resultStr = "";
    //     console.log(sentence);
    //     sentence.forEach((item) => {

    //       if (item?.word) {
    //         item.word.forEach((wt) => {
    //           console.log(wt.total_scorem,'99999999999999999999999999999999999999999');
    //           let flag = false;
    //           if (wt.syll?.phone) {
    //             flag = wt.syll.phone.some((pt) => pt?.perr_msg != 0);
    //           } else {
    //             wt.syll.forEach((st) => {
    //               if (Array.isArray(st?.phone)) {
    //                 flag = st.phone.some((pt) => pt?.perr_msg != 0);
    //               }
    //             });
    //           }
    //           if (flag) {
    //             resultStr += `<span class="err">${wt.content}</span>`;
    //           } else {
    //             resultStr += wt.content;
    //           }
    //         });
    //       } else if (item?.syll) {
    //         let flag = false;
    //         if (item.syll?.phone) {
    //           flag = item.syll.phone.some((pt) => pt?.perr_msg != 0);
    //         } else {
    //           item.syll.forEach((st) => {
    //             if (Array.isArray(st?.phone)) {
    //               flag = st.phone.some((pt) => pt?.perr_msg != 0);
    //             }
    //           });
    //         }
    //         if (flag) {
    //           resultStr += `<span class="err">${item.content}</span>`;
    //         } else {
    //           resultStr += item.content;
    //         }
    //       }
    //     });
    //     if (resultStr) {
    //       document.getElementById("right").style.display = "block";
    //       document.getElementById("result").innerHTML = resultStr;
    //     } else {
    //       document.getElementById("right").style.display = "none";
    //     }
    //   }
    //   if (jsonData.code === 0 && jsonData.data.status === 2) {
    //     this.iseWS.close();
    //   }
    //   if (jsonData.code !== 0) {
    //     this.iseWS.close();
    //     console.error(jsonData);
    //   }
    // },
    // toBase64(buffer) {
    //   var binary = "";
    //   var bytes = new Uint8Array(buffer);
    //   var len = bytes.byteLength;
    //   for (var i = 0; i < len; i++) {
    //     binary += String.fromCharCode(bytes[i]);
    //   }
    //   return window.btoa(binary);
    // },

    // sendAudioChunks(blob) {
    //   const chunkSize = 1024 * 1024; // 切片大小，这里以1MB为例
    //   let start = 0;
    //   let end = chunkSize;

    //   // 将Blob对象切片并发送到服务器
    //   while (start < blob.size) {
    //     const chunk = blob.slice(start, end);
    //     const formData = new FormData();
    //     formData.append('audioChunk', chunk);

    //     start = end;
    //     end = start + chunkSize;
    //   }
    // },

    // //

    // ksly() {
    //   // 调用方法
    //   const iframeWindow = this.$refs.myIframe.contentWindow;
    //   iframeWindow.postMessage('开始录音', '*');
    //   // console.log(this.analyser);
    //   this.setupAudio();
    //   this.setupCanvas();
    //   this.draw();
      
    // },

    // async setupAudio() {
    //   //异步方法，用于设置音频环境。
    //   //使用 navigator.mediaDevices.getUserMedia 方法请求用户媒体设备（麦克风）的权限
    //   //返回一个 MediaStream 对象。
    //   const stream = await navigator.mediaDevices.getUserMedia({
    //     audio: true,
    //   });
    //   //创建一个新的 AudioContext 对象，用于处理音频。
    //   this.audioContext = new AudioContext();
    //   //创建 AnalyserNode 对象，用于分析音频频谱。
    //   this.analyser = this.audioContext.createAnalyser();
    //   //创建一个 MediaStreamAudioSourceNode 对象，表示从媒体流中读取数据。
    //   const microphone = this.audioContext.createMediaStreamSource(stream);
    //   //将媒体流连接到分析器节点。
    //   microphone.connect(this.analyser);
    //   //设置 FFT（Fast Fourier Transform）大小，用于控制音频数据的分析精度。
    //   this.analyser.fftSize = this.bufferSize;
    // },
    // //设置 Canvas 元素的方法
    // setupCanvas() {
    //   // 获取组件中引用的 Canvas 元素。
    //   const canvas = this.$refs.canvas;
    //   // 获取 Canvas 2D 上下文，用于绘制图形。
    //   this.canvasContext = canvas.getContext("2d");
    //   // 设置 Canvas 宽度。
    //   canvas.width = this.canvasWidth;
    //   // 设置 Canvas 高度。
    //   canvas.height = this.canvasHeight;
    //   // 设置画布背景颜色为黑色。
    //   this.canvasContext.fillStyle = "#000";
    //   // 绘制一个填充矩形，覆盖整个画布，用黑色填充。
    //   this.canvasContext.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
    // },
    // //这是一个负责绘制音频波形图的函数，并且会检查声音的分贝是否超过阈值。
    // draw() {
    //   //请求下一帧动画
    //   requestAnimationFrame(this.draw);
    //   if (this.analyser != null) {
    //     //当前不在录音的情况
    //     // 获取音频频域数据的数组长度
    //     const bufferLength = this.analyser.frequencyBinCount;
    //     // 创建一个无符号 8 位整数数组，用于存储音频频域数据
    //     const dataArray = new Uint8Array(bufferLength);
    //     // 从 AnalyserNode 获取音频频域数据
    //     this.analyser.getByteTimeDomainData(dataArray);
    //     // 设置画布的填充颜色为黑色
    //     this.canvasContext.fillStyle = "#000";
    //     // 绘制一个黑色的矩形，覆盖整个画布，用于清空画布
    //     this.canvasContext.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
    //     // 设置绘制线条的宽度为 2 像素
    //     this.canvasContext.lineWidth = 2;
    //     // 设置绘制线条的颜色为绿色
    //     this.canvasContext.strokeStyle = "#00ff00";
    //     // 开始绘制新路径
    //     this.canvasContext.beginPath();
    //     // 计算每个数据片段在画布上的宽度
    //     const sliceWidth = (this.canvasWidth * 1.0) / bufferLength;
    //     // 初始化 x 坐标
    //     let x = 0;
    //     // 遍历音频频域数据数组，绘制波形图
    //     for (let i = 0; i < bufferLength; i++) {
    //       // 将音频数据归一化到 [-1, 1] 的范围
    //       const v = dataArray[i] / 128.0;
    //       // 根据归一化的数据计算波形图在画布上的 y 坐标
    //       const y = (v * this.canvasHeight) / 2;
    //       // 如果是第一个数据点，则将画笔移动到该点；否则，从上一个点绘制直线到当前点
    //       if (i === 0) {
    //         this.canvasContext.moveTo(x, y);
    //       } else {
    //         this.canvasContext.lineTo(x, y);
    //       }
    //       // 更新 x 坐标
    //       x += sliceWidth;
    //     }
    //     // 绘制直线到画布的右边中央
    //     this.canvasContext.lineTo(this.canvasWidth, this.canvasHeight / 2);
    //     // 绘制路径
    //     this.canvasContext.stroke();

    //     // 检测声音分贝是否超过阈值
    //     this.average = this.calculateAverage(dataArray);

    //     // 如果平均值超过阈值
    //     console.log(this.average, this.threshold);
        
    //     if (this.average > this.threshold) {
    //       if (!this.isRecording) {
    //         this.startRecording(); //开始录音
    //       }
    //       this.silentTime = 0; //重置静音时间
    //     } else {
    //       if (this.isRecording) {
    //         // 开始计时，如果静音时间超过4秒，停止录音
    //         if (!this.silenceInterval) {
    //           this.silenceInterval = setInterval(() => {
    //             this.silentTime++;
    //             if (this.silentTime >= 5) {
    //               this.stopRecording();
                  
    //               clearInterval(this.silenceInterval);
    //               this.silenceInterval = null;
    //               this.silentTime = 0; //重置静音时间
                 
    //             }
    //           }, 1000);
    //         }
    //       }
    //     }
    //   }
    // },
    // // 定义了一个名为 calculateAverage 的方法，接受一个 dataArray 参数。
    // calculateAverage(dataArray) {
    //   // 初始化一个 sum 变量用于存储音频数据的总和。
    //   let sum = 0;
    //   // 获取 dataArray 的长度并存储在 length 变量中。
    //   const length = dataArray.length;
    //   // 使用 for 循环遍历 dataArray。
    //   // 对于每个元素，减去128并加到 sum 上。
    //   // 这是因为音频数据范围在0到255之间，128表示音量的中值。
    //   for (let i = 0; i < length; i++) {
    //     sum += dataArray[i] - 128;
    //   }
    //   // 返回音频数据的平均值。
    //   return sum / length;
    // },
    // // 控制音频录制的功能
    // async toggleRecording() {
    //   if (!this.isRecording) {
    //     try {
    //       // 请求用户麦克风的音频输入
    //       const stream = await navigator.mediaDevices.getUserMedia({
    //         audio: true,
    //       });

    //       // 创建 MediaRecorder 实例，用于录制音频
    //       this.mediaRecorder = new MediaRecorder(stream);

    //       // 添加事件监听器，当有音频数据可用时，将其存储到 audioChunks 中
    //       // 保存数据(数据有效)
    //       this.mediaRecorder.addEventListener("dataavailable", (event) => {
    //         if (event.data.size > 0) {
    //           this.audioChunks.push(event.data);
    //         }
    //       });
    //       // 开始录制媒体流
    //       this.mediaRecorder.start();
    //       console.log(this.mediaRecorder);
     
    //       this.isRecording = true;
          

          


    //     } catch (error) {
    //       console.error("录音失败...", error);
    //     }
    //   }
    // },
    // async stopRecording() {

    //   // 停止录制媒体流。
    //   this.mediaRecorder.stop();
    //   console.log(this.audioChunks);
    //   // 等待 MediaRecorder 停止录制完成
    //   await new Promise((resolve) => {
    //     this.mediaRecorder.addEventListener("stop", resolve);
    //   });

    //   // 创建一个 Blob 对象，用于存储录制的音频数据
    //   const blob = new Blob(this.audioChunks, {
    //     type: "audio/pcm",
    //   });
    //   console.log("Blob size:", blob.size);
    //   if (blob.size > 0) {
    //     console.log("Blob contains audio data");
    //   } else {
    //     console.log("Blob is empty");
    //   }
    //   console.log(blob);

    //   this.audioUrl = URL.createObjectURL(blob);
    //   console.log(this.audioUrl);

    //   console.log("this.audioChunks", this.audioChunks);
    //   console.log("已发送给后端！");

    //   const iframeWindow = this.$refs.myIframe.contentWindow;
    //   iframeWindow.postMessage('停止录音', '*');
    //   // 将录音数据发送给后端
    //   // 这里需要实现发送录音数据给后端的逻辑
    //   console.log("录音数据", blob);
    //   // 重置录音状态和录音数据数组
    //   this.isRecording = false;
    //   this.audioChunks = [];
    //   this.analyser = null;
    //   return;
    // },
    // async startRecording() {
    //   this.toggleRecording();
    //   console.log("录音中");
    // },

    close() {
      this.$router.back();
    },
    MyAiteacher() {
      uploadFiles.aiteacherGet().then((res) => {
        if (res.data.code == 200) {
          console.log(res.data);
          this.trimg = res.data.datas.img;
        }
      });
    },
    getstudycentre() {
      uploadFiles
        .studycentreCourseinfo({ course_id: this.course_id })
        .then((res) => {
          if (res.data.code == 200) {
            this.study_plan = res.data.datas.study_plan
            console.log(res.data);
            this.content = JSON.parse(JSON.stringify(res.data.datas.content));
            console.log(res.data.datas.grouplist);
            this.sdgaADh = JSON.parse(JSON.stringify(res.data.datas.grouplist));
            console.log(this.sdgaADh.length);
            this.knowledge = JSON.parse(JSON.stringify(res.data.datas.grouplist[0].A));

            // this.poptitle = 
            let a = []
            res.data.datas.content.map(item => {
              if(item.type == 'title') {
                a.push(item.content)
              }
            })
            this.poptitle = a[0]
            
            console.log(this.knowledge.A, "9997");
            if (this.sdgaADh.length == 1) {
              this.getimgbtn(1);
            } else if (this.sdgaADh.length == 2) {
              this.getimgbtn(2);
            } else if (this.sdgaADh.length == 3) {
              this.getimgbtn(3);
            }
          }
        });
    },
    getimgbtn(e) {
      if (e == 1) {
        this.zsdbtn = [
          {
            url: require("../assets/xiaobangyy/czbtn/zsdxz.png"),
            name: "A",
            zb: "76px 155px",
            zsdindex: "999",
          },
        ];
      } else if (e == 2) {
        this.zsdbtn = [
          {
            url: require("../assets/xiaobangyy/czbtn/zsdxz.png"),
            name: "A",
            zb: "76px 155px",
            zsdindex: "999",
          },
          {
            url: require("../assets/xiaobangyy/czbtn/zsdxz.png"),
            name: "B",
            zb: "-154px 114px",
            top: "-20px",
            zsdindex: "",
          },
        ];
      } else if (e == 3) {
        this.zsdbtn = [
          {
            url: require("../assets/xiaobangyy/czbtn/zsdxz.png"),
            name: "A",
            zb: "76px 155px",
            zsdindex: "999",
          },
          {
            url: require("../assets/xiaobangyy/czbtn/zsdxz.png"),
            name: "B",
            zb: "-154px 114px",
            top: "-20px",
            zsdindex: "",
          },
          {
            url: require("../assets/xiaobangyy/czbtn/zsdxz.png"),
            name: "C",
            zb: "76px 74px",
            top: "-20px",
            zsdindex: "",
          },
        ];
      }
    },
    zsdcut(e) {
      console.log(e);
      this.zsdbtn.map((item) => {
        item.zsdindex = "0";
      });

      if (e.name == "A") {
        this.knowledge = this.sdgaADh[0].A;
        e.zsdindex = "999";
      } else if (e.name == "B") {
        this.knowledge = this.sdgaADh[1].B;
        e.zsdindex = "999";
      } else if (e.name == "C") {
        this.knowledge = this.sdgaADh[3].C;
        e.zsdindex = "999";
      }
      console.log(this.knowledge);
    },
    cutr() {
      this.$router.push("/Myteacher");
    },
    aeskfjand() {
      this.$refs.audioPlayer.load();
      this.$refs.audioPlayer.play();
    },
    onstudy(e) {
      console.log(e);
         
      uploadFiles.readAloud({ text: e.content}).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.audioData = res.data.datas.base64;
          this.zsdplay();
        }
      });

      // uploadFiles.aichatsse({ text: ''+e.content+'解释这个单词或句子的意思',aid:e.ccid,dhid:e.ccid }).then((res) => {
      // // let data =JSON.parse(res.data)
        
      //   var response = JSON.parse(res.data);
      //   console.log(response);
     
        
        
      // });





    },
    onwzstudy() {

    },
    
    playAudio() {
      const audio = new Audio();
      audio.src = this.audioData;
      audio.play();
      audio.onended = () => {
        console.log("结束了");

        
      };
    },
    zsdplay() {
      const audio = new Audio();
      audio.src = this.audioData;
      audio.play();
      audio.onended = () => {
        console.log("结束了");
        
      };

    },
    firstLearning(e) {
      if(e == '第一次进入') {
        
        uploadFiles.studycentreCourseinfo({ course_id: this.course_id }).then((res) => {
          if (res.data.code == 200) {
            console.log(res.data.datas.content, "889");
            let title = []
            let aa = ''
            res.data.datas.content.map(item => {
              if(item.type == "title") {
                title.push(item.content)
              }
              if(item.type == "content") {
                this.textnr.push(item.content)
              }
              
                
              
            })
            console.log(title[0],'888');
            console.log(this.textnr[0]);
            
            
            this.asgufih ='同学，你好！欢迎来到小帮英语，我是你的AI老师沐沐。从今天开始，我将和你一起闯关，共同完成英语课程学习。这节课的学习内容是：'+title[0]+'。来，我们就开始吧！跟着我大声地朗读：'+this.textnr[0]+''
          }
        });
        localStorage.setItem("user","go") 
        
      }else {
        uploadFiles.studycentreCourseinfo({ course_id: this.course_id }).then((res) => {
          if (res.data.code == 200) {
            console.log(res.data.datas.content, "889");
            let title = []
            let aa = ''
            res.data.datas.content.map(item => {
              if(item.type == "title") {
                title.push(item.content)
              }
              if(item.type == "content") {
                this.textnr.push(item.content)
              }
              
                
              
            })
            console.log(title[0],'888');
            console.log(this.textnr[0]);
            
            
            this.asgufih ='同学，欢迎回来！这节课我们来学习：'+title[0]+'。来，我们就开始吧！跟着我大声地朗读：'+this.textnr[0]+''
           
          }
        });


      }
      
      

      
    },
    langdu() {
      // const message = { param1: this.textnr[0] };
      // this.$refs.myIframe.contentWindow.postMessage(message, '/score/example/ise/index.html');
      // return

      this.viewerUrl='/score/example/ise/index.html'+'?param1='+this.textnr[0]+'',
      uploadFiles.readAloud({ text: this.asgufih }).then((res) => {
                console.log(res);
                if (res.data.code == 200) {
                  this.audioData = res.data.datas.base64;
                  this.playAudio();
                }
              });
    },
    ReadAgain(e) {
      console.log(e,'-------------');
      if(e.devtoolsEnabled == true) return;
      let arr = ''
      let multi = []
      e.map(item => { 
        console.log(item);
        arr+=item+'、'
        multi.push(item)
      })
      console.log(multi,'00000');
      let sun = ''
      if(multi.length >1) {
        if(multi.indexOf('多个单词发音问题') !== -1) {
          sun = e[0]
          console.log(multi,'111111111111111');
          multi.pop()
          console.log(multi,'222222222222222');
          
        
        console.log(sun,arr);
        // this.viewerUrl='/score/example/ise/index.html'+'?param1='+stringify(multi)+'',
        const iframe = document.getElementById('my-iframe').contentWindow;
        iframe.postMessage(multi, '*');
        console.log(sun,'88888');
        uploadFiles.readAloud({ text: sun }).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.audioData = res.data.datas.base64;
                    this.playAudio();
                  }
                });

          return
        }

        sun = '哇，你很不错呦！只是'+arr+'这'+e.length+'个单词的发音不是很准确。来，跟我一起读：'+e[0]+''
        
      console.log(sun,arr);
      // this.viewerUrl='/score/example/ise/index.html'+'?param1='+stringify(multi)+'',
      const iframe = document.getElementById('my-iframe').contentWindow;
      iframe.postMessage(multi, '*');

      uploadFiles.readAloud({ text: sun }).then((res) => {
                console.log(res);
                if (res.data.code == 200) {
                  this.audioData = res.data.datas.base64;
                  this.playAudio();
                }
              });

      }else {
        console.log(multi,'007777');
        if(multi[0] == '多个单词发音问题') {
          multi = []
          console.log(multi,'最后');
          console.log(this.textnr[0],'1112222222222');
          
          sun = '哇，你非常棒，我们再整体读一遍： '+this.textnr[0]+' '
          this.viewerUrl='/score/example/ise/index.html'+'?param1='+this.textnr[0]+'',
          uploadFiles.readAloud({ text: sun }).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.audioData = res.data.datas.base64;
                    this.playAudio();
                  }
                });

          return

          
        }else {

          console.log('一个单词');
        
          // this.viewerUrl='/score/example/ise/index.html'+'?param1='+e[0]+'',

          sun = '哇，你很不错呦！只是'+arr+'这'+e.length+'个单词的发音不是很准确。来，跟我一起读：'+e[0]+''

          // this.viewerUrl='/score/example/ise/index.html'+'?param1='+e[0]+'',
          const iframe = document.getElementById('my-iframe').contentWindow;
          iframe.postMessage(multi, '*');
        
          uploadFiles.readAloud({ text: sun }).then((res) => {
                    console.log(res);
                    if (res.data.code == 200) {
                      this.audioData = res.data.datas.base64;

                      this.playAudio();
                    }
                  });




        }

        // if(multi.indexOf('多个单词发音问题') !== -1) {
        //   sun = e[0]
        //   multi.pop()
        
        // console.log(sun,arr);
        // // this.viewerUrl='/score/example/ise/index.html'+'?param1='+stringify(multi)+'',
        // const iframe = document.getElementById('my-iframe').contentWindow;
        // iframe.postMessage(multi, '*');
        //   console.log(sun,'88888');
          
        // uploadFiles.readAloud({ text: sun }).then((res) => {
        //           console.log(res);
        //           if (res.data.code == 200) {
        //             this.audioData = res.data.datas.base64;
        //             this.playAudio();
        //           }
        //         });

        //   return
        // }
        



      }
      this.ywtList.push(sun)
      
      

      
    },
    readContinue() {
      if(this.textnr.length == 0) {
        // 最后一次朗读
        console.log(this.knowledge);
        let dc = ''
        this.knowledge.map(item => {
          dc+= item.content+'、'
        })
        
        let aitxt = ''
        aitxt ='哇，你太棒了！课文部分你已经全部学完了！本节课需要掌握这几个知识点：'+dc+'，你都会了吗？假若有不会的，就点点它，我来为你解答：'
        console.log(this.textnr,'0000');
        this.viewerUrl='/score/example/ise/index.html'+'?param1='+this.textnr[0]+'',
        uploadFiles.readAloud({ text: aitxt }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.audioData = res.data.datas.base64;
            this.zsdplay();
          }
        });


        return
      }
      this.textnr.shift()
      
      
      let aitxt = ''
      aitxt ='哇，你太棒了！接下来继续跟着我大声地朗读：'+this.textnr[0]+''
      console.log(this.textnr,'0000');
      this.viewerUrl='/score/example/ise/index.html'+'?param1='+this.textnr[0]+'',
      uploadFiles.readAloud({ text: aitxt }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.audioData = res.data.datas.base64;
          this.playAudio();
        }
      });
      

    },
    ConfirmOk() {
      this.dljsas = false
      this.langdu()

    },
    guanbi() {
      this.$router.back();
    },
    onreadAloud() {
      // console.log(this.);
      

    },
    startSpeaking(event) {
    
      
      this.startSun = 0
        this.isLongPress = true;
        this.doltxt = '松开 结束'
        // this.startTime = setInterval(()=> {
        //   this.startSun+=1
        // },500)
        // setTimeout(()=> {
          
        // },1000)
        const iframeWindow = this.$refs.myIframe.contentWindow;
          iframeWindow.postMessage('开始录音', '*');
        
      
    },
      stopSpeaking() {
        if (this.isLongPress) {
          this.doltxt = '按住 说话'
          this.isLongPress = false;
          clearTimeout(this.timer);
          console.log(this.startSun);

          clearInterval(this.startTime)
          
          // if(this.startSun < 1) {
          //   this.$message({
          //       message: '说话时间太短！',
          //       type: "error",
          //   });
            

          // }else {
            
          // }
          // this.startSun = 0
          const iframeWindow = this.$refs.myIframe.contentWindow;
            iframeWindow.postMessage('停止录音', '*');
          // 长按结束后的逻辑，比如停止说话
          console.log('停止说话');
        }
      }

  },
};
</script>
<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.study-box {
  width: 100%;
  height: 100vh;
  background-image: url(../assets/xiaobangyy/xxbj.png);

  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* IE/Edge */
  user-select: none;         /* 标准语法 */
}
.triangle-box {
  position: absolute;
  right: 0;
  margin-top: 30%;
  margin-right: -45px;
}
.textbook {
    position: relative;
    display: flex;
    width: 77%;
    /* height: 675px; */
    height: 94%;
    background-image: url(../assets/xiaobangyy/shuben.png);
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 999;
    margin-right: 5%;
}

.triangle {
  position: relative;
  width: 50px;
  height: 65px;
  /* background-image: url(../assets/xiaobangyy/czbtn/zsdxz.png); */
  /* background-position: 76px 155px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.triangle2 {
  position: relative;
  width: 50px;
  height: 65px;
  background-image: url(../assets/xiaobangyy/czbtn/zsdxz.png);
  background-position: -154px 114px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: -25px;
  z-index: 999 !important;
}
.triangle3 {
  width: 50px;
  height: 65px;
  background-image: url(../assets/xiaobangyy/czbtn/zsdxz.png);
  background-position: 76px 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: -25px;
}
.trtext {
  position: absolute;
  font-size: 18px;
  margin-left: -20px;
}
.close-box {
  position: absolute;
  right: 0;
  margin-right: -50px;
  margin-top: 5%;
  width: 64px;
  height: 83px;
  background-image: url(../assets/xiaobangyy/czbtn/close.png);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 999;
}
.interior-box {
  width: 90%;
  height: 90%;
  background: #0da8e3;
  margin-left: 6px;
}
.anew-box {
  position: absolute;
  right: 0;
  width: 52px;
  height: 58px;
  background-image: url(../assets/xiaobangyy/czbtn/anew.png);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 999;
  margin-right: -29px;
  margin-top: 65%;
}
.dialogue-box {
  width: 205px;
  height: 92%;
}
.dialog-title {
  width: 200px;
  height: 200px;
  background: #006cd4;
  margin: auto;
  margin-bottom: 13px;
  border-radius: 20px;
  display: flex;
  padding: 0 5px;
}
.dialog-title2 {
  position: relative;
  width: 100%;
  height: 100%;
  background: #005ab9;
  border-radius: 20px;
  padding: 10px;
}
.dialog-body {
  width: 100%;
  height: calc(100% - 200px - 13px);

  background: #006cd4;
  border-radius: 30px;
  padding: 5px 0;
}
.dialog-body2 {
  width: 100%;
  height: 100%;

  background: #005ab9;
  border-radius: 30px;
  padding: 2px 8px;
}
.AI-dialogue {
  position: relative;
  width: 100%;
  height: 90%;
  background: #CEF4FF;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding-top: 10px;
  overflow-y: auto;
  
}

.AI-dialogue::-webkit-scrollbar {
  width: 4px; /* 滚动条宽度 */
}
.AI-dialogue::-webkit-scrollbar-track {
  background: #005ab9; /* 滚动条轨道颜色 */
}
.AI-dialogue::-webkit-scrollbar-thumb {
  background: #197ce4; /* 滚动条手柄颜色 */
}
.pressbtn {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f6f6;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 10px;
  background-image: url('../assets/xiaobangyy/luyingbtn.png');
  background-position: -39px 277px;
  font-size: 100%;
  color: #fff;
}
.aierror {
  width: 95%;
 
  margin: 5px auto;
  background: #fff;
  padding: 10px ;
  border-radius: 20px;
  text-align: left;
  

}
.switchover {
  position: absolute;
  bottom: 0;
  width: 70px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: red;
  color: #fff;
  border-bottom-left-radius: 21px;
  border-top-right-radius: 10px;
  margin-bottom: 10px;
}

.mytrimg {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.book-left,
.book-right {
  width: 50%;
  height: 78%;
  border-radius: 20px;
  padding: 20px 20px 0 20px;

  margin: auto;
}
.book-left {
  overflow-y: auto;
}
.book-right {
  position: relative;
  padding-right: 30px;
}
.bookimg {
  width: 80%;
  border-radius: 40px;
}
.bookTitle {
  font-size: 22px;
  margin: 10px 0 20px 0;
}
.bookText {
  font-size: 19px;
  color: #b5b6b6;
  margin: 10px 0;
  padding-left: 10px;
  text-align: left;
}
.book-cont-title {
  padding-left: 20px;
}

.book-left::-webkit-scrollbar {
  width: 0px; /* 滚动条宽度 */
}
.book-left::-webkit-scrollbar-track {
  background: #fff; /* 滚动条轨道颜色 */
}
.book-left::-webkit-scrollbar-thumb {
  background: #fff; /* 滚动条手柄颜色 */
}
.book-zsd-box {
  position: relative;
  width: 80%;
  height: 30px;
  font-size: 20px;
  line-height: 30px;
  margin: auto;
  background: #0da8e3;
  display: flex;
  justify-content: center;
  color: #fff;
  border: 0px;
  margin-bottom: 50px;
}
.tmzsj2 {
  position: absolute;
  right: 0;
  margin-right: -1px;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid #fff;
  border-left: 0px solid transparent;
  border-bottom: 15px solid transparent;
}
.tmzsj {
  position: absolute;
  left: 0;
  margin-left: -1px;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-left: 15px solid #fff;
  border-bottom: 15px solid transparent;
}
.zsdtext {
  margin-top: 15px;
  color: #b5b6b6;
  font-size: 19px;
  text-align: left;
  padding: 0 30px;
}
.skanfj {
  position: relative;
  line-height: 25px;
}
.zsdtesxt {
  margin-left: 25px;
}
.zsdzd {
  position: absolute;
  margin-top: 2px;
}
.zsdjdbox {
  width: 100%;
  height: 60px;

 

  bottom: 0;
}
.dfisiha {
  width: 100%;
  height: calc(100% - 140px);
  overflow-y: auto;
  padding-bottom: 10px;
}
.textbook2 {
  position: relative;
  width: 70%;
  height: 85%;
  display: flex;

  /* border: 13px solid #1099CD;
  border-radius: 15px;

  margin-right: 5%;
  display: flex;
  justify-content: center; */
}
.books-left {
  width: 48%;
  height: 100%;
  background: #fcfcfc;
  border-top: 13px solid #109acf;
  border-left: 13px solid #109acf;
  border-bottom: 13px solid #109acf;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.skahsfi {
  width: 30px;
  height: 30px;
  margin-top: -11px;
  margin-left: -10px;
  background-image: url(../assets/xiaobangyy/book/left-top-border.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
.saskan {
  width: 30px;
  height: 100%;
  margin-left: -10px;
  margin-top: -5px;
  background-image: url("../assets/xiaobangyy/book/left-border(1).png");

  background-size: 100%;
}
.cut-off {
  height: 100%;
  width: 20px;
  background: red;
}
.booktop {
  display: flex;
}
.sajfg {
  width: 45px;
  height: 60px;
  background-image: url(../assets/xiaobangyy/book/top-left.png);
}
.consa {
  width: 100%;
  height: 60px;
  background-image: url(../assets/xiaobangyy/book/top.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.status-box {
  width: 75%;
  margin: auto;
 
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wcstatus-1 {
  width: 50px;
  height: 50px;
  background-image: url(../assets/xiaobangyy/state/icon.png);
  background-position: -427px 72px;
}
.wcwire1 {
  flex: 1;
  height: 2px;
  background: #d0f8ff;
}
.status-2 {
  width: 50px;
  height: 50px;
  background-image: url(../assets/xiaobangyy/state/icon.png);
  background-position: -194px 72px;
}
.wire {
  flex: 1;
  height: 2px;
  background: #ebe9e9;
}
.status-3 {
  width: 50px;
  height: 50px;
  background-image: url(../assets/xiaobangyy/state/icon.png);
  background-position: -626px 72px;
}


.xxbox {
    
    position: fixed; /* 定位方式 */
    top: 0; /* 顶部对齐 */
    left: 0; /* 左边对齐 */
    width: 100%; /* 宽度为100% */
    height: 100%; /* 高度为100% */
    background: rgba(30,55,58,0.5); /* 半透明背景 */
    z-index: 1000; /* 高的z-index值确保遮罩层在其他内容之上 */
    display: flex;
    justify-content: center;
    align-items: center;
  }
    .asfjx {
      position: relative;
    width: 400px;
    height: 390px;
    background: #989EA5;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
      
    }
    .sdksnjg {
      width: 360px;
    height: 300px;
    background: #fff;
    z-index: 9999;
    border-radius: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    }
    .lajjs {
      top: 0;
      left: 0;
      position: absolute;
      margin-top: -50px;
      margin-left: -20px;
    }
    .rajjs {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -50px;
      margin-right: -20px;
  
    }
    .jsxfd {
      width: 300px;
      height: 300px;
      position: absolute;
      top: 0;
      margin-top: -150px;
      background: url(../assets/xiaobangyy/pop/logo.png);
      display: flex;
      justify-content: center;
      align-items: center;
  
    }
    .vkah {
      font-size: 25px;
      color: red;
      margin-top: 80px;
    }
    .aksuzjxf {
      width: 85%;
      height: 70%;
      margin: auto;
      padding-top: 90px;
      font-size: 18px;
      text-align: left;
  
    }
    .ksasgd {
      width: 85%;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }
</style>
