<template>
  <div class="login-box">
    <div class="log-title">
      <div style="display: flex; align-items: flex-end">
        <img class="xblogo" src="../assets/xiaobangyy/logo.png" alt="" />
        <div class="xbtit">小帮英语</div>
      </div>
    </div>
    <div class="log-body">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item class="log--form-item"  prop="tel">
            <img class="log-image" src="../assets/xiaobangyy/icon(1).png" alt="">
          <el-input class="log-input" placeholder="请输入您的学习账号" v-model="ruleForm.tel" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item class="log--form-item"  prop="pwd">
          <img class="log-image2" src="../assets/xiaobangyy/icon.png" alt="">

          <el-input class="log-input" placeholder="输入6至16位密码" v-model="ruleForm.pwd" :type="Typecut" maxlength="16"></el-input>
          <img v-if="dkahsx" class="log-image3" src="../assets/xiaobangyy/icon3.png" alt="" @click="cuttype">
          <img  v-else class="log-image3" src="../assets/xiaobangyy/shut.png" alt="" @click="cuttype">

          <!-- <el-button class="log-button" type="primary" @click="submitForm('ruleForm')">立即登录</el-button> -->
          
        </el-form-item>
        <img class="log-button" src="../assets/xiaobangyy/logbtn.png" alt="" @click="submitForm('ruleForm')">
      </el-form>
    </div>
  </div>
</template>
<script>
import * as uploadFiles from "@/api/uploadFiles";
export default {
  data() {
    return {
      dkahsx:true,
      ruleForm: {
        tel: "13700000001",
        pwd: "123456",
        choose:'pwd'
        
      },
      Typecut:'password',
      rules: {
        tel: [
            { required: true, message: '请输入您的学习账号', trigger: 'blur' },
            { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
          
          ],
          pwd: [
            { required: true, message: '请输入6至16位密码', trigger: 'blur' },
            { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
          ],
      }
    };
  },
  methods:{
    submitForm(ruleForm) {
        console.log('112');
        this.$refs[ruleForm].validate((valid) => {
          if (valid) {
            console.log();
            uploadFiles.userLogin(this.ruleForm).then((res)=> { 
              console.log(res);
              if(res.data.code == 200) {
                console.log(res.data.datas.token);
                localStorage.setItem('token', res.data.datas.token);
                this.$message({
                  message: '登录成功！',
                  type: "success",
                });
                this.$router.push('/home')

                // this.$router.push({
                //   path: "/Home",
                //   query: {
                //     keywords: this.input,
                //     type: this.type,
                //     date:new Date().getTime()
                //   }
                // })

              }else {
                console.log(res.data.msg);
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }

            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    cuttype() {
        console.log(this.Typecut);
        this.dkahsx = !this.dkahsx
        console.log(this.dkahsx);
        this.Typecut = this.Typecut === "password" ? "text" : "password";
    }
  }
};
</script>
<style >
.login-box {
  width: 100%;
  height: 100vh;
  background-image: url(../assets/xiaobangyy/loginbj.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
.log-title {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 120px;
}
.xblogo {
  width: 90px;
}
.xbtit {
  font-size: 45px;
  color: #fff;
  font-weight: bold;
}
.log-body {
    margin-top: 100px;
    width: 310px;
    margin: auto;
}
.log--form-item {
    margin: 30px 0;
    position: relative;
}
.log-image {
    position: absolute;
    z-index: 999;

    margin-top: 20px;
    margin-left: 21px;
}
.log-image2 {
    position: absolute;
    z-index: 999;
    margin-left: 6px;
    margin-top: -4px;
}
.log-image3 {
    position: absolute;
    z-index: 999;
    margin-top: 15px;
    margin-left: -60px;
}
.el-form-item__content {
    margin-left: 0 !important;
}
.el-input__inner {
    width: 300px;
    height: 70px;
    background: #003796;
    border: 2px solid #0090FF;
    border-radius: 35px;
    font-size: 20px;
    color: #fff;
    padding-left: 50px;
    padding-right: 60px;
}
.log-button {
    width: 300px;
    height: 75px;
    margin-top: 30px;
    border-radius: 35px;
    color: #fff;
}
.el-form-item__error {
    left: 30%;
    margin-top: 4px;
    font-size: 14px;
}
</style>
